
import { apiTemplateAmazonSiteDelTemplate } from '@/api/setting'
import { apiModelModelApplyLists, apiModelModelApplyGetStatus, apiModelModelApplyDesigner } from '@/api/model'
import { apiShopLists, apiDesignerLists } from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Task extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging()
    apiModelModelApplyLists = apiModelModelApplyLists
    form: any = {
        id: ''
    }
    applyList = []
    statusList = []

    queryObj:any = {
        status: -10,
        shop_id: ''
    }

    page_no = 1
    page_size = 10
    keyWord: string = ''
    limit = 0
    userList = []
    loadMoreMsg: string = '——————加载完毕——————'
    hasMore: boolean = false
    searchId = 0

    showDesignerModal = false
    keyword = ''
    designerPager: RequestPaging = new RequestPaging()
    apiDesignerLists = apiDesignerLists
    clickId: null | number = null 

    onAssign(row: any) {
        apiModelModelApplyDesigner({
            id: this.clickId,
            designer_id: row.id
        }).then((res:any)=>{
            this.clickId = null;
            this.showDesignerModal = false
            this.getList()
        })
    }

    getDesignerList() {
        this.designerPager
            .request({
                callback: apiDesignerLists,
                params: {
                    keyword: this.keyword
                }
            })
    }

    reset() {
        this.queryObj = {
            status: -10,
            shop_id: ''
        }
        this.getList()
    }
    
    initUserList(init = true) {
        let params = {
            page_no: this.page_no,
            page_size: this.page_size,
            shop_info: this.keyWord
        }
        // @ts-ignore
        this.searchId ? (params.id = this.searchId) : ''
        apiShopLists(params).then((res: any) => {
            this.limit = res.count
            if (init) {
                this.userList = res.lists
            } else {
                this.userList = this.userList.concat(res.lists)
            }

            console.log('this.userList.length', this.userList.length)
            console.log('this.limit', this.limit)
            console.log(this.userList.length >= this.limit)
            if (this.userList.length >= this.limit) {
                this.loadMoreMsg = '——————加载完毕——————'
                this.hasMore = false
            } else {
                this.loadMoreMsg = '——————加载更多——————'
                this.hasMore = true
            }
        })
    }

    loadMore() {
        console.log(this.userList.length, '-------------', this.limit)
        if (!this.hasMore) return false
        this.page_no += 1
        this.initUserList(false)
    }
    remoteMethod(keyWord: string) {
        this.page_no = 1
        this.keyWord = keyWord
        this.initUserList(true)
    }

    clearKeyWord() {
        this.keyWord = ''
        this.searchId = 0
        this.initUserList(true)
    }

    // 获取列表
    async getList() {
        this.pager
            .request({
                callback: apiModelModelApplyLists,
                params: {
                    ...this.queryObj
                }
            })
            .then(res => {
                this.pager.lists.forEach((v: any) => {
                    this.statusList.forEach((v1: any) => {
                        if (v.status == v1.key) {
                            this.$set(v, 'status_ch', v1.value)
                            this.$set(v, 'status_color', v1.color)
                        }
                    })
                })
            })
    }

    // 删除这个定时任务
    async onDel(id: any): Promise<void> {
        await apiTemplateAmazonSiteDelTemplate({ id: id })
        this.getList()
    }

    // 新增
    goTaskAdd() {
        this.$router.push({
            path: '/setting/site/site_template_edit',
            query: {
                site_id: this.$route.query.id,
                mode: PageMode['ADD']
            }
        })
    }

    // 下载模板
    goDownLoad(item: any) {
        if (item?.template?.url) window.open(item.template.url, '_blank')
    }

    // 分配设计师
    setDesigner(id: number) {
        this.clickId = id;
        this.getDesignerList();
        this.showDesignerModal = true;
    }

    // 编辑
    goTaskEdit(id: number) {
        this.$router.push({
            path: '/model/apply_edit',
            query: {
                id: id as any,
                mode: PageMode['EDIT']
            }
        })
    }

    // 获取状态
    getStatus() {
        apiModelModelApplyGetStatus({})
            .then((res: any) => {
                this.statusList = res
                this.getList()
            })
            .catch(() => {})
    }

    created() {
        this.getStatus()
        this.initUserList(true);
    }
}
